import './App.css';
import { Route, Routes } from 'react-router-dom';
import Home from './Pages/Home/Home.tsx';
import Header from './Components/Header/Header';
import Category from './Pages/Category/Category';
import SideMenu from './Components/SideMenu/SideMenu';
import ProductDetails from 'Pages/ProductDetails/ProductDetails';
import SearchResults from 'Pages/SearchResults/SearchResults';
import RedirectAli from 'Pages/RedirectAli/RedirectAli';

function App() {
  
  return (
    <div>
        <div className="AppHeader">
          <Header />
        </div>
        <div className="AppBody">
          <div className="AppContentContainer">
                <Routes>
                  <Route element={<Home />} path='/' />
                  <Route element={<Category />} path='/page/:categoryId' />
                  <Route element={<ProductDetails />} path='/page/:categoryId/:productId' />
                  <Route element={<SearchResults />} path='/search/:searchPhrase' />
                  <Route element={<RedirectAli />} path='/item/:itemId' />
                </Routes>
            </div>
            <div className="AppMenuContainer">
                <SideMenu />
            </div>
        </div>
    </div>
  );
}

export default App;
