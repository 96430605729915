/*
    **********************************************************************************************
    **********************************************************************************************

    Firebase Database connection
    
    **********************************************************************************************
    **********************************************************************************************
// */
// import { FirebaseApp } from "firebase/app";
// import { 
//     getCategoryProducts as _getCategoryProducts,
//     getCategory as _getCategory,
//     getProduct as _getProduct,
//     getCategoryItems as _getCategoryItems,
//     getCategories as _getCategories,
//     getAllParentCategoryIds as _getAllParentCategoryIds,
//     search as _search,
//     init as _init,
//     initData as _initData
//  } 
//  from "googleFirebase/firebaseDatabase";

// export function initDatabase(app?: FirebaseApp) {
//     _init(app);
// }

// export async function initData() {
//     await _initData();
// }

// export function getCategories() {
//     return _getCategories();
// }

// export function getCategoryItems() {
//     return _getCategoryItems();
// }

// export async function getProduct(id: string, categoryId: string) {
//     return await _getProduct(id, categoryId);
// }

// export function getCategory(id: string) {
//     return _getCategory(id);
// }

// export async function getCategoryProducts(categoryId: string) {
//     return await _getCategoryProducts(categoryId);
// }

// export function getAllParentCategoryIds(currentCategoryId: string) {
//     return _getAllParentCategoryIds(currentCategoryId);
// }

// export async function search(searchPhrase: string, limit?: number | undefined) {
//     return await _search(searchPhrase, limit);
// }

/*
    **********************************************************************************************
    **********************************************************************************************

    Local Database connection
    
    **********************************************************************************************
    **********************************************************************************************
*/

import { 
    getCategoryProducts as _getCategoryProducts,
    getCategory as _getCategory,
    getProduct as _getProduct,
    getProducts as _getProducts,
    getCategoryItems as _getCategoryItems,
    getCategories as _getCategories,
    getAllParentCategoryIds as _getAllParentCategoryIds,
    search as _search,
    initDatabase as _initDatabase,
    getAllParentCategoryNames as _getAllParentCategoryNames
 } 
 from "utils/localDatabase";

export function initDatabase() {
    _initDatabase();
}

export async function initData() {
    
}

export function getCategories() {
    return _getCategories();
}

export function getCategoryItems() {
    return _getCategoryItems();
}

export function getProducts() {
    return _getProducts();
}

export async function getProduct(id: string, categoryId: string) {
    return _getProduct(id);
}

export function getCategory(id: string) {
    return _getCategory(id);
}

export async function getCategoryProducts(categoryId: string) {
    return _getCategoryProducts(categoryId);
}

export function getAllParentCategoryIds(currentCategoryId: string) {
    return _getAllParentCategoryIds(currentCategoryId);
}

export function getAllParentCategoryNames(currentCategoryId: string) {
    return _getAllParentCategoryNames(currentCategoryId);
}

export async function search(searchPhrase: string, limit?: number | undefined) {
    return _search(searchPhrase, limit);
}