import React, { useEffect, useRef, useState } from "react";
import './Category.css';
import Product from "../../Components/Product/Product";
import { useParams } from "react-router-dom";
import { getCategory, getCategoryProducts } from "utils/itemsDatabaseFunctions"
import { IProduct } from "types/types";
import { logScreenView } from "googleFirebase/firebaseAnalytics";
import Loading from "Components/Loading/Loading";
import { getAllParentCategoryNames } from "utils/localDatabase";

const Category = () => {
    const { categoryId } = useParams();
    const [products, setProducts] = useState<IProduct[]>([]);
    const [categoryName, setCategoryName] = useState('');
    const [loading, setLoading] = useState(false);
    const containerDivRef = useRef(null);
    const [categoryPath, setCategoryPath] = useState('');

    useEffect(() => {
        containerDivRef.current.scrollTo(0,0);
        setLoading(true);
        logScreenView('Category', categoryId);
        getCategoryProducts(categoryId).then(products => setProducts(products)).finally(() => setLoading(false));
        setCategoryName(getCategory(categoryId).name);

        let allParentNames = getAllParentCategoryNames(categoryId);
        let path = '';
        for(let i=0; i<allParentNames.length; i++) {
            path += allParentNames[i];
            if(i < allParentNames.length - 1) {
                path += ' > ';
            }
        }
        setCategoryPath(path)
    }, [categoryId]);

    return (
        <div>
            <div dir="rtl" className="CategoryPathContainer">{categoryPath}</div>
            <div className="CategoryContainer" ref={containerDivRef}>
                <Loading loading={loading} title={categoryName}/>
                {products.map((product, _) => {
                    return (
                        <div className="CategoryProductContainer" key={product.id}>
                            <Product product={product} />
                        </div>);
                })}
            </div>
        </div>)
};


export default Category;