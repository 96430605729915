import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const RedirectAli = () => {
  const location = useLocation();

  useEffect(() => {
    const path = location.pathname;
    const postfix = path.substring(path.lastIndexOf('/') + 1);
    const newUrl = `https://s.click.aliexpress.com/e/${postfix}`;
    window.location.href = newUrl;
  }, [location]);

  return <div dir="rtl">טוען הנחות ומעביר אותך לעלי אקספרס</div>;
};

export default RedirectAli;