import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './constants.css';
import App from './App';
import {BrowserRouter} from 'react-router-dom';
import { init } from 'googleFirebase/firebaseInit';
import { init as analyticsInit} from 'googleFirebase/firebaseAnalytics';
import { initDatabase, initData } from 'utils/itemsDatabaseFunctions';
import { init as storageInit} from 'googleFirebase/firebaseStorage';

//firebase
var firebaseApp = init();
analyticsInit(firebaseApp);
//storageInit(firebaseApp);
//initDatabase(firebaseApp);
await initData();

//local
initDatabase();

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>
);
