import React, { useEffect, useState } from "react";
import './CategoryItem.css';
import { MdExpandMore, MdExpandLess } from "react-icons/md";
import { ICategoryItem } from "./types";
import { useNavigate } from "react-router-dom";
import { getAllParentCategoryIds } from "utils/itemsDatabaseFunctions";
import { log } from "googleFirebase/firebaseAnalytics";

interface IProps {
    category: ICategoryItem;
    onLeafCategoryChosen?: () => void;
}

const CategoryItem = ({category, onLeafCategoryChosen}: IProps) => {
    const shouldShowExpandIcon = category.subCategories.length > 0;
    const [isExpanded, setIsExpanded] = useState(false);
    const [isChosen, setIsChosen] = useState(false);
    const navigate = useNavigate();

    const handleClicked = () => {
        log('Category clicked', {name: category.name, id: category.id, wasExpanded: !isExpanded && shouldShowExpandIcon});
        setIsExpanded(!isExpanded);

        if(category.subCategories.length === 0) {
            //redirect
            navigate('/page/'+category.id);
            if(onLeafCategoryChosen !== undefined) {
                onLeafCategoryChosen();
            }
        }
    }

    useEffect(() => {
        if(window.location.pathname.includes('/page/')) {
            const categoryId = window.location.pathname.split('/page/')[1].split('/')[0];
            const parentIds = getAllParentCategoryIds(categoryId);
            if(parentIds.includes(category.id)){
                setIsExpanded(true);
            } else {
                setIsExpanded(false);
            }

            if(categoryId === category.id) {
                setIsChosen(true);
            } else {
                setIsChosen(false);
            }
        }
    }, [window.location.pathname])

    return (
        <div className="CategoryItemContainer">
            <div className={"CategoryItemInnerContainer " + (isChosen ? "CategoryItemInnerContainerChosen" : undefined)} onClick={handleClicked}>
                <p>{category.name}&nbsp;</p>
                {shouldShowExpandIcon 
                ? isExpanded ? <MdExpandLess /> : <MdExpandMore />
                : undefined}
             </div>
             {isExpanded && 
                <div className="CategoryItemSubCategoriesContainer">
                    {category.subCategories.sort((a,b)=> b.order - a.order).map((value, _) => {
                        return (<CategoryItem category={value} onLeafCategoryChosen={onLeafCategoryChosen} key={value.id}/>);
                    })}    
                </div>}
        </div>)
};


export default CategoryItem;