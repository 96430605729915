import React, { useEffect, useRef, useState } from "react";
import './Loading.css';
import { Default } from "react-awesome-spinners";
import date from 'date-and-time';

interface IProps {
    loading: boolean;
    title?: string;
}

const Loading = ({loading, title}: IProps) => {
    const [showLongLoadingText, setShowLongLoadingText] = useState(false);
    var time: Date | null = null;
    const timeRef = useRef(time);
    const longLoadingTimeInSeconds = 8;

    useEffect(() => {
        if(loading) {
            document.body.style.overflow = 'hidden';
            timeRef.current = new Date();
            setTimeout(checkLongLoadingTime, longLoadingTimeInSeconds * 1000);
        } else {
            document.body.style.overflow = 'auto';
            timeRef.current = null;
            setShowLongLoadingText(false);
        }
    }, [loading]);

    const checkLongLoadingTime = () => {
        let now = new Date();
        if(timeRef.current !== null &&
            date.subtract(now, timeRef.current).toSeconds() > longLoadingTimeInSeconds) {
                setShowLongLoadingText(true);
            }
    }

    const loadingComponent = () => {
        return (
            <div className="LoadingContainer">
                <div dir="rtl" className="LoadingTitle">טוען {title}...</div>
                <div><Default color={'#FF5C5C'} size={64} sizeUnit='px' /></div>
                {showLongLoadingText && 
                <div dir="rtl" className="LoadingTitle" style={{width: '80%'}}>
                    נראה שלוקח הרבה זמן לטעון את המוצרים, אני ממליצה לך לרפרש את העמוד ולנסות שוב
                </div>}
            </div>
        );
    }

    return (
        <>
            {loading 
                ? <>{loadingComponent()}</>
                : <></>}
        </>);
};


export default Loading;