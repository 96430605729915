import React, { useEffect, useRef, useState } from "react";
import './PhoneMenu.css';
import { IoMenu } from "react-icons/io5";
import { HiOutlineMenuAlt3 } from "react-icons/hi";
import SideMenu from "Components/SideMenu/SideMenu";


const PhoneMenu = () => {
    const [open, setOpen] = useState(false);
    const menuDivRef = useRef(null);
    
    const handleClicked = () => {
        setOpen(!open);
    }

    useEffect(() => {
        function handleClickOutside(event) {
          if (menuDivRef.current && !menuDivRef.current.contains(event.target)) {
            closeMenu();
          }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
          document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [menuDivRef]);

    const closeMenu = () => {
        setOpen(false);
    }

    return (
        <div className="PhoneMenuContainer">
            <div className="PhoneMenuHamburgerContainer">
            {open 
                ? <HiOutlineMenuAlt3 size={35} className="PhoneMenuIcon" onClick={handleClicked}/>
                : <IoMenu size={35} className="PhoneMenuIcon" onClick={handleClicked}/>}
                <span>קטגוריות</span>
            </div>
            <div className={"PhoneMenu_categoriesContainer " + (open ? "PhoneMenu_show" : "PhoneMenu_hide")} ref={menuDivRef}>
                <SideMenu onCategoryChosen={closeMenu} />
            </div>
        </div>)
};


export default PhoneMenu;