import { Analytics, getAnalytics, logEvent } from "firebase/analytics";
import { FirebaseApp } from "firebase/app";

var analytics: Analytics;

export function init(app: FirebaseApp) {
    analytics = getAnalytics(app);
}

export function log(eventName: string, params?: any) {
    logEvent(analytics, eventName, params);
}

export function logScreenView(screenName: string, categoryId: string = null, productId: string = null) {
    let params : any = {};
    if(productId !== null) {
        params.productId = productId;
    }
    if(categoryId !== null) {
        params.categoryId = categoryId;
    }

    log('Screen_View_'+screenName, params);
}