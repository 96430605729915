import React, { useEffect, useState } from "react";
import './SideMenu.css';
import CategoryItem from "./CategoryItem/CategoryItem";
import { ICategoryItem } from "./CategoryItem/types";
import { useCategoryStore } from "Stores/CategoryStore";

interface IProps {
    onCategoryChosen?: () => void
}

const SideMenu = ({onCategoryChosen}: IProps) => {
    const categoryStore = useCategoryStore();
    const [categoryItems, setCategoryItems] = useState<ICategoryItem[]>([]);
    
    useEffect(() => {
        categoryStore.getCategoryItems().then(response => setCategoryItems(response.sort((a, b) => (b.order - a.order))));
    }, []);

    return (
        <div className="SideMenuContainer">
            {categoryItems.map((category, _) => {
                return (<CategoryItem category={category} key={category.id} onLeafCategoryChosen={onCategoryChosen}/>);
                })}
        </div>)
};


export default SideMenu;