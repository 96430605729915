import React, { useEffect, useState } from "react";
import './HomeBanner.css';
import { log } from "googleFirebase/firebaseAnalytics";

interface IProps {
    image: any;
    title: string;
    url: string;
    isNewWindow: boolean;
}

const HomeBanner = ({image, title, url, isNewWindow}: IProps) => {

    return (
        <div className="HomeBannerContainer" dir="rtl">
             <a href={url} target={isNewWindow ? "_blank" : "_self"} rel="noreferrer" onClick={() => {log('Banner_Clicked', {banner: title})}}>
                <img src={image} alt={title} />
            </a>
        </div>)
};


export default HomeBanner;