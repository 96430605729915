import React from 'react';
import {makeObservable, action, observable} from 'mobx';
import { ICategoryItem } from 'Components/SideMenu/CategoryItem/types';
import { getCategoryItems } from "utils/itemsDatabaseFunctions";

class CategoryStore {
  categoryItems: ICategoryItem[] = [];
  lastCategoryItemsFetch: number = 0;

  constructor() {
    makeObservable(this, {
        categoryItems: observable,
        fetchCategoryItems: action.bound,
    });
  }

  async fetchCategoryItems() {
    this.categoryItems = await getCategoryItems();
    this.lastCategoryItemsFetch = new Date().getTime();

    return this.categoryItems;
  }

  async getCategoryItems(reset: boolean = false) {
    var time = new Date().getTime() - this.lastCategoryItemsFetch;
    if(reset || this.categoryItems.length === 0 || time > 600000 /* 10 Minutes */) {
        await this.fetchCategoryItems();
    }

    return this.categoryItems;
  }

}

const categoryStore = new CategoryStore();
export const CategoryStoreContext = React.createContext(categoryStore);
export const useCategoryStore = () => React.useContext(CategoryStoreContext);
