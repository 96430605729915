import React, { useEffect, useRef, useState } from "react";
import './SearchResults.css';
import { search } from "utils/itemsDatabaseFunctions";
import { IProduct } from "types/types";
import { useParams } from "react-router-dom";
import Product from "Components/Product/Product";

const SearchResults = () => {
    const { searchPhrase } = useParams();
    const [searchResults, setSearchResults] = useState<IProduct[]>([]);
    
    useEffect(() => {
        search(searchPhrase).then((searchResults) => {
            setSearchResults(searchResults);
        });
    }, [searchPhrase]);


    return (
        <div className="CategoryContainer">
            {searchResults.map((product, _) => {
                return (
                    <div className="CategoryProductContainer">
                        <Product product={product} searchPage={true}/>
                    </div>);
            })}
        </div>)
};


export default SearchResults;