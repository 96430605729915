import React, { useEffect, useState } from "react";
import './Header.css';
import SearchBar from "../SearchBar/SearchBar";
import iconImage from "../../assets/icon2.png";
import ksp from "../../assets/ksp.png";
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import PhoneMenu from "Components/PhoneMenu/PhoneMenu";

const Header = () => {
    const kspCoupon = '4baby';
    const isPhone = window.innerWidth <= 768;

    const copyToClipboard = async (content: string) => {
        if(isPhone) {
            return;
        }
        
        await navigator.clipboard.writeText(content ?? '');
        toast('הועתק');
    };

    return (
        <div className="HeaderOuterContainer">
            <div className="HeaderMessageContainer" dir="rtl" onClick={() => {copyToClipboard(kspCoupon)}}>
                <ToastContainer autoClose={10} draggable closeButton={false} hideProgressBar={false} />
                <span>קוד קופון פעיל ל</span>
                <span>{kspCoupon} :KSP</span>
                <img src={ksp} alt="KSP" />
            </div>
            {isPhone && <div className="HeaderPhoneIconContainer">
                <div className="HeaderIcon HeaderPhoneIcon">
                    <a href="/"><img src={iconImage} alt="icon" /></a>
                </div>
            </div>}
            <div className="HeaderContainer">
                <div className="HeaderFiller"></div>
                <div className="HeaderSearchBar">
                    <SearchBar />
                </div>
                {!isPhone && <div className="HeaderIcon HeaderDesktopIcon">
                    <a href="/"><img src={iconImage} alt="icon" /></a>
                </div>}
                <div className="HeaderPhoneMenuContainer">
                    <PhoneMenu />
                </div>
            </div>
        </div>)
};


export default Header;