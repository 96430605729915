import React, { useEffect, useState } from "react";
import './BuyNowButton.css';
import { log } from "googleFirebase/firebaseAnalytics";

interface IProps {
    link: string;
    small?: boolean;
}

const BuyNowButton = ({link, small = false}: IProps) => {
    const isPhone = window.innerWidth <= 768;
    const navigateToProduct = () => {
        log('BuyNow', {link: link});
        window.open(link,'_blank', 'noreferrer');
    }

    return (
        <div className="BuyNowButtonContainer" dir="rtl">
                <button className={"BuyNowButton " + (small ? "BuyNowButtonSmall" : "")} onClick={navigateToProduct}>
                    לרכישה בAliExpress
                </button>
        </div>)
};


export default BuyNowButton;