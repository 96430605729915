import React, { useEffect, useRef, useState } from "react";
import './SearchBar.css';
import { CiSearch } from "react-icons/ci";
import { search } from "utils/itemsDatabaseFunctions";
import { IProduct } from "types/types";

const SearchBar = () => {
    const [searchPhrase, setSearchPhrase] = useState('');
    const [searchResults, setSearchResults] = useState<IProduct[]>([]);
    const searchDivRef = useRef(null);

    useEffect(() => {
        function handleClickOutside(event) {
          if (searchDivRef.current && !searchDivRef.current.contains(event.target)) {
            closeSearchDiv();
          }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
          document.removeEventListener("mousedown", handleClickOutside);
        };
      }, [searchDivRef]);

    const handleSearch = async () => {
        if(searchPhrase.length < 3) {
            setSearchResults([]);
            return;
        }

        const searchResults = await search(searchPhrase, 5);
        setSearchResults(searchResults);
    }

    const onKeyUp = (e) => {
        if(e.key === 'Enter'){
            handleSearch();
        }
        if(e.key === 'Escape') {
            closeSearchDiv();
        }
    }

    const onTextChange = (e) => {
        setSearchPhrase(e.target.value);
        handleSearch();
    }

    const closeSearchDiv = () => {
        setSearchPhrase('');
        setSearchResults([]);
    }

    const getSearchResultItem = (product: IProduct) => {
        return (
            <a href={"/page/" + product.categoryId + "/" + product.id} key={product.id}>
                <div className="SearchBarSearchResult">
                    <img src={product.imageUrls[0]} alt={product.title} />
                    <div className="SearchBarSearchResultDetails">
                        <span className="SearchBarSearchResultsTitle">{product.title}</span>
                        <span className="SearchBarSearchResultsPrice">{product.price}</span>
                    </div>
                </div>
            </a>
        );
    }

    return (
        <div className="SearchBarOuterContainer" dir="rtl">
            <div className="SearchBarContainer" dir="rtl">
                <input 
                    type="text" 
                    placeholder="חפשו באתר ותתכוננו להתאהב..." 
                    className="SearchBarInput" 
                    value={searchPhrase} 
                    onChange={onTextChange} 
                    onKeyUp={onKeyUp} 
                    />
                <div className="SearchBarIconContainer">
                    <CiSearch size={window.innerWidth <= 768 ? 20 : 30}/>
                </div>
            </div>
            {searchPhrase.length > 2 && <div className="SearchBarResultsContainer" ref={searchDivRef}>
               {searchResults.map((value, _) => {
                    return getSearchResultItem(value);
               })}
               {searchResults.length > 0 
               ? <div className="SearchBarResultsShowMoreContainer"><a href={"/search/"+searchPhrase}><span>הצג הכל</span></a></div> 
               : <div className="SearchBarResultsNoResultsContainer"><span>לא נמצאו תוצאות</span></div>}
            </div>}
        </div>)
};


export default SearchBar;