import { DocumentData } from "firebase/firestore/lite";
import { IDatabaseCategory, IProduct } from "types/types";

const _imageUrls = [
    'https://img.kwcdn.com/product/open/2023-06-25/1687676020278-0229dcd36454482a9b229c6d83adb67c-goods.jpeg',
    'https://cdn.kideno.com/wp-content/uploads/2023/02/1005004829674697_var_EVA-raincover.jpg',
    'https://cdn.kideno.com/wp-content/uploads/2023/02/1005004829674697_6-600x600.jpg'
];

export function parseProductDatabase(documentData: any) {
    const product: IProduct = {
        categoryId: documentData.categoryId,
        description: documentData.description,
        id: documentData.id,
        link: documentData.link,
        price: documentData.price,
        title: documentData.title,
        //TODO: Change once new products are live
        imageUrls: documentData.imageUrls ?? [documentData.imageUrl, _imageUrls[0], _imageUrls[1], _imageUrls[2]],
        store: documentData.store ?? 'AliExpress',
        creationTime: documentData.creationTime
    };

    return product;
}

export function parseCategoryDatabase(documentData: any) {
    const category: IDatabaseCategory = {
        id: documentData.id,
        name: documentData.name,
        parentId: documentData.parentId,
        subCategoryIds: documentData.subCategoryIds,
        order: documentData.order,
        productIds: documentData.productIds
    };

    return category;
}