import React, { useEffect, useState } from "react";
import './Home.css';
import { logScreenView } from "googleFirebase/firebaseAnalytics";
import HomeBanner from "Components/HomeBanner/HomeBanner";
import { useNavigate } from "react-router-dom";
import facebook from '../../assets/banners/facebook_banner.png';
import wallStickers from '../../assets/banners/wall_stickers.png';
import brit from '../../assets/banners/brit.png';
import birth_banner from '../../assets/banners/birth_banner.png';

const Home = () => {
    const navigate = useNavigate();
    const isPhone = window.innerWidth <= 768;

    useEffect(() => {
        logScreenView('Home');
        if(!isPhone) {
            navigate('/page/3531038160');
        }
    }, []);

    return (
        <div className="HomeContainer" dir="rtl">
            <HomeBanner 
                image={facebook} 
                title='facebook' 
                url="https://www.facebook.com/groups/253488273811618/?ref=share&mibextid=K8Wfd2"
                isNewWindow={true}/>     
            <HomeBanner 
                image={wallStickers} 
                title='מדבקות קיר' 
                url="/page/2414566021"
                isNewWindow={false}/>   
            <HomeBanner 
                image={birth_banner} 
                title='לקראת לידה' 
                url="/page/3531038160"
                isNewWindow={false}/>         
        </div>)
};


export default Home;
