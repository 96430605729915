import React, { useEffect, useState } from "react";
import './Product.css';
import { IProduct } from "types/types";
import { useNavigate } from "react-router-dom";
import BuyNowButton from "Components/BuyNowButton/BuyNowButton";
import { IoIosArrowForward, IoIosArrowBack } from "react-icons/io";
import aliexpress from '../../assets/aliexpress.png';
import amazon from '../../assets/amazon.png';
import ksp from '../../assets/ksp.png';
import { log } from "googleFirebase/firebaseAnalytics";

interface IProps {
    product: IProduct;
    searchPage?: boolean
}

const Product = ({product, searchPage = false}: IProps) => {
    const isPhone = window.innerWidth <= 600 ? true : false;
    const navigate = useNavigate();
    const [imageIndex, setImageIndex] = useState(0);
    const arrowSize = isPhone ? window.innerWidth * 0.08 : 40;
    const storeImage = 
        product.store === 'Amazon'
            ? amazon
            : product.store === 'KSP'
                ? ksp
                : aliexpress;
    
    const goToProductPage = async () => {
        log('clickd on product');
        if(searchPage) {
            navigate('/page/' + product.categoryId + '/' + product.id);
        } else {
            navigate(product.id);
        }
    }

    const forwardImageIndex = () => {
        if(imageIndex === product.imageUrls.length - 1) {
            setImageIndex(0);
        } else {
            setImageIndex(imageIndex + 1);
        }
    }

    const backwardImageIndex = () => {
        if(imageIndex === 0) {
            setImageIndex(product.imageUrls.length - 1);
        } else {
            setImageIndex(imageIndex - 1);
        }
    }

    return (
        <div className="ProductOuterBackgroundContainer">
            <div className="ProductOuterContainer">
                <div className="ProductContainer">
                        <div className="ProductImageContainer">
                            <IoIosArrowBack size={arrowSize} className="ProductImageArrows" onClick={backwardImageIndex}/>
                            <div onClick={goToProductPage} className="ProductImage" style={{backgroundImage: `url(${product.imageUrls[imageIndex]})`}}></div>
                            <IoIosArrowForward size={arrowSize} className="ProductImageArrows" onClick={forwardImageIndex}/>
                        </div>
                        <div className="ProductTitle" dir="rtl">
                            <span title={product.title}>{product.title}</span>
                        </div>
                        {<div className="ProductDescription">
                            {product.description.length > 20 && <span className="ProductDescriptionReadMore" onClick={goToProductPage}>קרא עוד</span>}
                            <span className="ProductDescriptionText">{product.description}</span>
                        </div>}
                        <div className="ProductPrice" dir="rtl">
                            <span>{product.price}</span>
                        </div>
                    <div className="ProductButtonsContainer">
                        <div className="ProductBuyNowButtonContainer">
                           <BuyNowButton link={product.link} small/>
                        </div>
                    </div>
                </div>
            </div>
        </div>)
};


export default Product;