import React, { useEffect, useState } from "react";
import './ProductDetails.css';
import { IProduct } from "types/types";
import { useParams } from "react-router-dom";
import { getAllParentCategoryNames, getProduct } from "utils/itemsDatabaseFunctions";
import BuyNowButton from "Components/BuyNowButton/BuyNowButton";
import { logScreenView } from "googleFirebase/firebaseAnalytics";

const ProductDetails = () => {
    const { categoryId, productId } = useParams();
    const [product, setProduct] = useState<IProduct | null>(null);
    const [categoriesParentIds, setCategoriesParentIds] = useState<string[]>([]);

    useEffect(() => {
        logScreenView('Product', categoryId, productId);
        getProduct(productId, categoryId).then(product => {
            setProduct(product);
        });
    }, [productId]);

    useEffect(() => {
        if(product) {
            const parentIds = getAllParentCategoryNames(product.categoryId);
            setCategoriesParentIds(parentIds);
        }
    }, [product]);

    return (
        <div className="ProductDetailsContainer" dir="rtl">
            <div className="ProductDetailsTextDetailsContainer">
                <div className="ProductDetailsCategoriesContainer" dir="rtl">
                    {categoriesParentIds.map((_value, index) => {
                        return (<>
                            {index > 0 && <span>{">"}</span>}
                            <span>{_value}</span>
                        </>);
                    })}
                </div>
                <div className="ProductDetailsTitleContainer">
                        <span>{product?.title ?? ''}</span>
                </div>
                <div className="ProductDetailsDescriptionContainer">
                        <span>{product?.description}</span>
                </div>
                <div className="ProductDetailsPriceContainer">
                        <span>{product?.price}</span>
                </div>
                <div className="ProductDetailsButtons">
                    <BuyNowButton link={product?.link} />
                </div>
           </div>
           <div className="ProductDetailsImagesContainer">
                {product?.imageUrls.map((value, index) => {
                    return (<div className="ProductDetailsImage" style={{backgroundImage: `url(${value})`}} key={index}></div>);
                })}
           </div>
        </div>)
};


export default ProductDetails;