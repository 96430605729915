import { IDatabaseCategory, IProduct } from 'types/types';
import { ICategoryItem } from 'Components/SideMenu/CategoryItem/types';
import { parseCategoryDatabase, parseProductDatabase } from './parsers';
//import allCategoriesJson from '../Database/Categories/Categories.json';
import { Categories } from '../Database/Categories/CategoriesObject';
//import allProductsJson from '../Database/Products/Products.json';
import { Products } from '../Database/Products/ProductsObject'

var allCategories: IDatabaseCategory[] = [];
var allProducts: IProduct[] = [];

export function initDatabase() {
    //const allCategoriesData = allCategoriesJson.data; 
    const allCategoriesData = Categories.data; 
    allCategoriesData.forEach(categoryData => {
        if(!categoryData.name.includes("hidden")) {
            allCategories.push(categoryData)
        }
    });

    //const allProductsData = allProductsJson.data; 
    const allProductsData = Products.data; 
    allProductsData.forEach(productData => allProducts.push(productData));
}

export function getCategories() {
    return allCategories;
}

export function getProducts() {
    return allProducts;
}

export function getCategoryItems() {
    const categories = getCategories();
    const items = categories.map(category => {
        const response: ICategoryItem = {
            id: category.id,
            name: category.name,
            subCategories: [],
            page: '',
            hasProducts: category.productIds.length > 0,
            order: category.order
        }

        return response;
    });
    var displayItems = items.filter(x => (categories.find(y => y.id === x.id)).parentId.length === 0);
    categories.forEach(category => {
        if(category.subCategoryIds.length > 0) {
            var item1 = items.find(x => x.id === category.id);
            var subItems = items.filter(x => category.subCategoryIds.includes(x.id));
            item1.subCategories = subItems;
        }
    });

    return displayItems;
}

export function getProduct(id: string) {
    const product = allProducts.find(x => x.id === id);
    return parseProductDatabase(product);
}

export function getCategory(id: string) {
    const category = allCategories.find(x => x.id === id);
    if(category === undefined) {
        console.log(id)
    }

    return parseCategoryDatabase(category);
}

export function getCategoryProducts(categoryId: string) {
    const categoryData = getCategory(categoryId);
    const productIds: string[] = categoryData.productIds;
    if(productIds.length === 0) {
        return [];
    }

    const response: IProduct[] = [];
    productIds.forEach(productId => {
        const productItem = getProduct(productId);
        response.push(parseProductDatabase(productItem));
    });
   
    return response;
}

export function search(searchPhrase: string, limit?: number | undefined) {
    if(searchPhrase.length < 3) {
        return [];
    }

    var searchResults = allProducts.filter(x => (x.title.includes(searchPhrase) || x.description.includes(searchPhrase)) && allCategories.findIndex(cat => cat.id == x.categoryId) > -1);
    if(limit !== undefined) {
        return searchResults.slice(0, limit);
    }

    return searchResults;
}

export function getAllParentCategoryIds(currentCategoryId: string) {
    const category = getCategory(currentCategoryId);
    if(category.parentId.length === 0) {
        return [];
    }

    const grandParentIds = getAllParentCategoryIds(category.parentId);
    const parentIds: string[] = grandParentIds;
    parentIds.push(category.parentId);
    
    return parentIds;
}

export function getAllParentCategoryNames(currentCategoryId: string) {
    const category = getCategory(currentCategoryId);
    if(category.parentId.length === 0) {
        return [category.name];
    }

    const grandParentNames = getAllParentCategoryNames(category.parentId);
    const parentNames: string[] = grandParentNames;
    parentNames.push(category.name);
    
    return parentNames;
}

//add

export function addCategory(category: IDatabaseCategory) {
    allCategories.push(category);
}

export function addSubCategory(id: string, subCategoryId: string) {
    allCategories.find(x => x.id === id).subCategoryIds.push(subCategoryId);
}

export function addProductId(categoryId: string, productId: string) {
    let category = allCategories.find(x => x.id === categoryId);
    if(category.productIds === undefined) {
        category.productIds = [];
    }
    category.productIds.push(productId);
}

export function addProduct(product: IProduct) {
    allProducts.push(product);
}

export function deleteCategory(id: string) {
    allCategories = allCategories.filter(x => x.id !== id);
}

export function deleteProduct(id: string) {
    allProducts = allProducts.filter(x => x.id !== id);
}